import { vdBodyTypesPath } from "../../../lib/api/Api";
import { MethodData, useAxios } from "../../../lib/custom-axios/CustomAxios";
import { browseMarketplaceByBodyType, browseMarketplaceByLocation } from "../marketing-utils";
import Link from "../../../lib/link/Link";

const Footer: React.FC = () => {
  const {data: bodyTypes} = useAxios(vdBodyTypesPath, {
    method: MethodData.GET
  })
  return (
    <footer>
      <div className="container custom-container d-flex align-items-start">
        <div className="d-flex flex-column footer-links">
          <div className="footer-logo">
            <Link legacyBehavior href="/" passHref>
                <a>
                    <img width="125" src="/marketing-assets/images/logo.svg" />
                </a>
            </Link>
            <div className="d-flex justify-content-end flex-column align-items-end hide-in-large-screens">
              <h2 className="footer-header">Schedule a call with us</h2>
              <Link legacyBehavior href="/contact" passHref><a className="btn btn-purr">Get in touch</a></Link>
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row gap-3 gap-sm-0 justify-content-between align-items-start">
            <div className="footer-links w-100">
              <p className="mb-1"><strong>Browse cars</strong></p>
              <ul>
                <div className="d-flex flex-column w-100">
                  <div className="col-6 col-sm-12">
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByBodyType(bodyTypes, 'Convertibles')}>
                        Convertibles
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByBodyType(bodyTypes, 'Coupes')}>
                        Coupes
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByBodyType(bodyTypes, 'Full-size Van')}>
                        Full-size vans
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByBodyType(bodyTypes, 'Hatchbacks')}>
                        Hatchbacks
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByBodyType(bodyTypes, 'Mini-vans')}>
                        Minivans
                      </Link>
                    </li>
                  </div>
                  <div className="col-6 col-sm-12">
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByBodyType(bodyTypes, 'Pickup Trucks')}>
                        Pickup trucks
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByBodyType(bodyTypes, 'SUVs')}>
                        SUVs
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByBodyType(bodyTypes, 'Sedan')}>
                        Sedans
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByBodyType(bodyTypes, 'Specialty Vehicles')}>
                        Specialty vehicles
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByBodyType(bodyTypes, 'Wagon')}>
                        Wagons
                      </Link>
                    </li>
                  </div>
                  <p className="mt-3 mb-1 hide-in-large-screens"><strong>Find a dealer</strong></p>
                  <ul className="hide-in-large-screens">
                    <li>
                      <Link legacyBehavior href="/marketplace">Browse all dealerships</Link>
                    </li>
                  </ul>
                </div>
              </ul>
            </div>
            <div className="footer-links hide-in-mobile me-xl-5">
              <p className="mb-1"><strong>Find a dealer</strong></p>
              <ul>
                <li>
                  <Link legacyBehavior href="/marketplace">Browse all dealerships</Link>
                </li>
              </ul>
            </div>
            <div className="footer-links w-100">
              <p className="mb-1"><strong>Purr proudly serves</strong></p>
              <ul className="proudly-serves">
                <Link legacyBehavior href={browseMarketplaceByLocation('51.253775', '-87.323214', 'administrative_area_level_1')}>
                  <a><strong>Ontario</strong></a>
                </Link>
                <div className="d-flex">
                  <div className="col-6">
                    <li>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('43.653226', '-79.3831843', 'locality')}>
                        Toronto
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('43.464258', '-80.52041', 'locality')}>
                        Waterloo
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('43.361621', '-80.314428', 'locality')}>
                        Cambridge
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('43.45164', '-80.492534', 'locality')}>
                        Kitchener
                      </Link>
                    </li>
                  </div>
                  <div className="col-6">
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('43.139387', '-80.264425', 'locality')}>
                        Brantford
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('43.544805', '-80.248167', 'locality')}>
                        Guelph
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('43.370001', '-80.982229', 'locality')}>
                        Stratford
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('51.253775', '-87.323214', 'administrative_area_level_1')}>
                        Surrounding Areas
                      </Link>
                    </li>
                  </div>
                </div>
                <li className="mt-3">
                  <Link legacyBehavior href={browseMarketplaceByLocation('54.4216878', '-119.4841678', 'administrative_area_level_1')}>
                    <a><strong>Central Alberta</strong></a>
                  </Link>
                </li>
                <div className="d-flex">
                  <div className="col-6">
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('52.3065443', '-114.0973313', 'locality')}>
                        Sylvan Lake
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('52.268975', '-113.8115599', 'locality')}>
                        Red Deer
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('52.38087909999999', '-113.7954188', 'locality')}>
                        Blackfalds
                      </Link>
                    </li>
                  </div>
                  <div className="col-6 align-self-end">
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('52.4684651', '-113.7306644', 'locality')}>
                        Lacombe
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('52.136699', '-113.8723875', 'locality')}>
                        Penhold
                      </Link>
                    </li>
                    <li>
                      <Link legacyBehavior href={browseMarketplaceByLocation('52.02739219999999', '-113.9501957', 'locality')}>
                        Innisfail
                      </Link>
                    </li>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end flex-column align-items-end hide-in-mobile">
          <h2 className="footer-header">Schedule a <br className="hide-in-large-screens" />call with us</h2>
          <Link legacyBehavior href="/contact" passHref><a className="btn btn-purr">Get in touch</a></Link>
        </div>
      </div>
      <div className="container custom-container">
        <div className="d-flex align-items-start justify-content-between footer-menu-wrapper">
          <div className="footer-menu">
            <Link legacyBehavior href="/buy">Buy</Link>
            <Link legacyBehavior href="/sell">Sell</Link>
            <Link legacyBehavior href="/about">About Purr</Link>
          </div>
          <div className="social-links-wrapper">
            <Link legacyBehavior href="/dealer">Dealers</Link>
            <div className="footer-social-links">
              <a href="https://www.instagram.com/purr.ca" target="_blank">
                <img width="33" src="/marketing-assets/images/social/PURR_SM_Icons-Instagram.svg" />
              </a>
              <a href="https://www.facebook.com/Purr-103458345622743" target="_blank">
                <img width="33" src="/marketing-assets/images/social/PURR_SM_Icons-Facebook.svg" />
              </a>
              <a href="https://www.linkedin.com/company/purr-ca/about/?viewAsMember=true" target="_blank">
                <img width="33" src="/marketing-assets/images/social/PURR_SM_Icons-Linkedin.svg" /></a>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-start justify-content-between footer-copyright">
          <Link legacyBehavior href="/dealer">Dealers</Link>
          <div>
            <Link legacyBehavior href="/terms">Terms & Conditions</Link>
            <Link legacyBehavior href="/privacy">Privacy Policy</Link>
          </div>
          <div>&copy; {new Date().getFullYear()} PURR&trade; All rights reserved.</div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
